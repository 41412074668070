import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/right-arrow-black.svg'
import _imports_1 from '@/assets/images/google-logo.png'


const _hoisted_1 = { class: "body_wrap login_page" }
const _hoisted_2 = { class: "innerpage_formCard" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "cmn_cardInner space" }
const _hoisted_5 = { class: "row justify-content-center" }
const _hoisted_6 = { class: "col-lg-12" }
const _hoisted_7 = { class: "form_area text-center mb-3 form-row cmn-form-wrap" }
const _hoisted_8 = { class: "form-group col-md-6" }
const _hoisted_9 = { class: "formGroup_inner" }
const _hoisted_10 = { class: "form-group col-md-6" }
const _hoisted_11 = { class: "formGroup_inner" }
const _hoisted_12 = { class: "form-group col-md-6" }
const _hoisted_13 = { class: "formGroup_inner" }
const _hoisted_14 = { class: "form-group col-md-6" }
const _hoisted_15 = { class: "formGroup_inner" }
const _hoisted_16 = { class: "form-group col-md-6" }
const _hoisted_17 = { class: "formGroup_inner" }
const _hoisted_18 = { class: "form-group col-md-6" }
const _hoisted_19 = { class: "formGroup_inner" }
const _hoisted_20 = { class: "form-group col-md-6" }
const _hoisted_21 = { class: "formGroup_inner" }
const _hoisted_22 = { class: "form-group col-md-6" }
const _hoisted_23 = { class: "formGroup_inner" }
const _hoisted_24 = { class: "text-success text-center w-100" }
const _hoisted_25 = { class: "text-danger text-center w-100" }
const _hoisted_26 = {
  key: 0,
  class: "email-sent",
  role: "alert"
}
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "col-lg-6 mx-auto" }
const _hoisted_29 = { class: "anotherLogin text-center" }
const _hoisted_30 = { class: "login_btns" }
const _hoisted_31 = { class: "form-text text-muted dont_have_account" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_section),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[27] || (_cache[27] = _createElementVNode("h3", { class: "sairaR mb-5 col-md-12" }, "Registration Form", -1)),
                _createElementVNode("div", _hoisted_8, [
                  _cache[11] || (_cache[11] = _createElementVNode("label", { for: "firstname" }, "First Name ", -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _cache[10] || (_cache[10] = _createElementVNode("i", { class: "fa fa-user" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "aria-describedby": "firstnameHelp",
                      placeholder: "First Name",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstname) = $event)),
                      maxlength: "35"
                    }, null, 512), [
                      [_vModelText, _ctx.firstname]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", { for: "lastname" }, "Last Name ", -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fa fa-user" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      "aria-describedby": "lastnameHelp",
                      placeholder: "Last Name",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastname) = $event)),
                      maxlength: "35"
                    }, null, 512), [
                      [_vModelText, _ctx.lastname]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", { for: "username" }, "Username *", -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fa fa-user" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      maxlength: "35",
                      "aria-describedby": "userNameHelp",
                      placeholder: "Username",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.username) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.username]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", { for: "answerInputEmail1" }, "Email Address *", -1)),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[16] || (_cache[16] = _createElementVNode("i", { class: "fa fa-envelope-o" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "email",
                      class: "form-control",
                      "aria-describedby": "emailHelp",
                      placeholder: "Email",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.email) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.email]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[19] || (_cache[19] = _createElementVNode("label", { for: "answerInputPassword1" }, "Password *", -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[18] || (_cache[18] = _createElementVNode("i", { class: "fa fa-key" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "password",
                      class: "form-control",
                      placeholder: "Password",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.password]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[21] || (_cache[21] = _createElementVNode("label", { for: "inputProfession" }, "Profession", -1)),
                  _createElementVNode("div", _hoisted_19, [
                    _cache[20] || (_cache[20] = _createElementVNode("i", { class: "fa fa-graduation-cap" }, null, -1)),
                    _createVNode(_component_Multiselect, {
                      class: "form-control",
                      placeholder: "Select a Profession",
                      modelValue: _ctx.selectedProfession,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedProfession) = $event)),
                      options: _ctx.professions
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _cache[23] || (_cache[23] = _createElementVNode("label", { for: "inputCountry" }, "Country", -1)),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[22] || (_cache[22] = _createElementVNode("i", { class: "fa fa-globe" }, null, -1)),
                    _createVNode(_component_Multiselect, {
                      class: "form-control",
                      placeholder: "Select a Country",
                      modelValue: _ctx.selectedCountry,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedCountry) = $event)),
                      options: _ctx.countries
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _cache[25] || (_cache[25] = _createElementVNode("label", { for: "inputlanguage" }, "Language", -1)),
                  _createElementVNode("div", _hoisted_23, [
                    _cache[24] || (_cache[24] = _createElementVNode("i", { class: "fa fa-language" }, null, -1)),
                    _createVNode(_component_Multiselect, {
                      class: "form-control",
                      placeholder: "Select a language",
                      modelValue: _ctx.selectedLanguage,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
                      options: _ctx.languages
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.success), 1),
                _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.error), 1),
                (!_ctx.waitingEmail)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      class: "col-md-12 cm_bg cm-btn text-white mt-4",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onSubmit())),
                      loading: _ctx.isLoading
                    }, {
                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                        _createTextVNode(" Submit "),
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "",
                          class: "ml-1"
                        }, null, -1)
                      ])),
                      _: 1
                    }, 8, ["loading"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.waitingEmail)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    _createElementVNode("img", {
                      src: _ctx.emailSentSvg,
                      class: "mr-3 image-sent",
                      alt: "success message"
                    }, null, 8, _hoisted_27),
                    _cache[28] || (_cache[28] = _createElementVNode("h4", { class: "alert-heading" }, " Check your inbox for the confirmation email ", -1))
                  ]))
                : _createCommentVNode("", true),
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "differ_option" }, [
                _createElementVNode("span", null, "OR")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _cache[33] || (_cache[33] = _createElementVNode("h3", { class: "sairaR mb-4" }, "Other Login Options", -1)),
                _createElementVNode("ul", _hoisted_30, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_a_button, {
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onGoogleSignIn())),
                      class: "cm-btn google_btn"
                    }, {
                      default: _withCtx(() => _cache[30] || (_cache[30] = [
                        _createElementVNode("img", {
                          src: _imports_1,
                          alt: "Google Logo image"
                        }, null, -1),
                        _createTextVNode(" Sign In With Google ")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("small", _hoisted_31, [
                  _cache[32] || (_cache[32] = _createTextVNode("Already have an account? ")),
                  _createVNode(_component_router_link, {
                    to: { name: 'Login' },
                    class: "cm-color"
                  }, {
                    default: _withCtx(() => _cache[31] || (_cache[31] = [
                      _createTextVNode("Log in")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}