
import { languages, countries } from "countries-list";
import { defineComponent } from "@vue/runtime-core";
import firebase from "firebase/compat/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import Multiselect from "@vueform/multiselect";
import { PROFESSIONS } from "../../../constants/registration";
import {
  validateEmail,
  validateFirstname,
  validateLastname,
  validatePassword,
  validateUserName,
} from "@toppick/common/build/validators";
import { auth } from "@/services/firebase";
import { ActionTypes } from "@/store/actions";
import { UserCreated } from "@toppick/common/build/interfaces";
import { registerUser } from "@toppick/common/build/api";
import { getErrorMessage } from "@toppick/common/build/utils";
import HeaderSection from "./HeaderSection.vue";
import "../styles.css";


export default defineComponent({
  components: { Multiselect, HeaderSection },
  data() {
    return {
      isLoading: false,
      error: "" as string,
      success: "" as string,
      firstname: "" as string,
      lastname: "" as string,
      username: "" as string,
      email: "" as string,
      password: "" as string,
      selectedCountry: "" as string,
      selectedLanguage: "" as string,
      selectedProfession: "" as string,
      emailSentSvg: require("@/assets/images/email-sent.svg"),
      waitingEmail: false,
    };
  },
  methods: {
    validDateForm() {
      if (!this.username || !this.email || !this.password) {
        throw new Error("Fill all required fields (*)");
      }
      if (!validateFirstname(this.firstname)) {
        throw new Error("Invalid first name");
      }
      if (!validateLastname(this.lastname)) {
        throw new Error("Invalid last name");
      }
      if (!validateUserName(this.username)) {
        throw new Error("Invalid user name");
      }
      if (!validatePassword(this.password)) {
        throw new Error("Type a stronger password");
      }
      if (!validateEmail(this.email)) {
        throw new Error("Invalid email provided");
      }
    },

    async createUser(): Promise<firebase.User> {
      const userData: UserCreated = {
        firstname: this.firstname,
        lastname: this.lastname,
        country: this.selectedCountry,
        language: this.selectedLanguage,
        profession: this.selectedProfession,
        password: this.password,
        username: this.username,
        email: this.email,
      };
      await registerUser(userData);
      const { user } = await auth.signInWithEmailAndPassword(
        this.email,
        this.password
      );
      if (!user) {
        throw new Error("Failed to Authenticate");
      }
      return user;
    },

    async verifyEmail(user: firebase.User): Promise<void> {
      return new Promise<void>((resolve) => {
        setTimeout(async () => {
          user.reload();
          if (!user.emailVerified) {
            await this.verifyEmail(user);
            resolve();
          } else {
            await this.$store.dispatch(ActionTypes.SET_USER, user);
            resolve();
          }
        }, 3000);
      });
    },

    async onSubmit() {
      try {
        this.waitingEmail = false;
        this.isLoading = true;
        this.error = "";
        this.validDateForm();
        const user = await this.createUser();
        await sendEmailVerification(user);
        this.waitingEmail = true;
        await this.verifyEmail(user);
        this.success = "Successfully registered!";
        setTimeout(() => {
          this.$router.push({
            name: "Home",
          });
        }, 1000);
      } catch (error) {
        this.error = getErrorMessage(error);
      }
      this.isLoading = false;
    },

    async onGoogleSignIn() {
      try {
        this.isLoading = true;
        this.error = "";
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        await signInWithPopup(auth, provider);
        this.success = "Successfully signed in!";
        setTimeout(() => {
          this.$router.push({
            name: "Home",
          });
        }, 1000);
      } catch (error) {
        this.error = "Failed to sign in with Google";
      }
      this.isLoading = false;
    },
  },
  computed: {
    countries() {
      return Object.keys(countries).map((key) => ({
        value: key,
        label: countries[key].name,
      }));
    },

    professions() {
      return PROFESSIONS.map((val) => ({ value: val, label: val }));
    },

    languages() {
      return Object.keys(languages).map((key) => ({
        value: key,
        label: languages[key].name,
      }));
    },
  },
});
